// Popover

.popover {
  // scss-docs-start popover-css-vars
  --#{$prefix}popover-border-bottom-width: #{$popover-border-bottom-width};
  // scss-docs-end popover-css-vars

  // Additional MDB Angular styles
  position: unset;
  opacity: 0;
  // Additional MDB Angular styles

  // .popover-arrow {
  //   display: none;
  // }
}

.popover-header {
  border-bottom: var(--#{$prefix}popover-border-bottom-width) solid
    var(--#{$prefix}popover-border-color);
}
