/* You can add global styles to this file, and also import other style files */
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import '@fortawesome/fontawesome-free/css/all.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  background-color: aliceblue;
}

body > * {
  width: 100%;
  min-height: 100vh;
  max-width: 1600px;
}

body > div {
  /* layout being uncentered due elfsigh injecting divs at the top. Hiding them */
  display: none;
}

main {
  min-height: calc(100vh - 60px);
}

.page {
  background-color: white;
}

.page .content {
  padding: 30px 10px 40px 10px;
}

h2 {
  font: 500 20px/32px Roboto, sans-serif!important;
  color: #d97a2d;
}

h3 {
  font: 400 16px/28px Roboto, sans-serif!important;
  color: #d97a2d;
  font-weight: bold;;
}