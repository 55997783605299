// CORE FUNCTIONS
@import './bootstrap/functions';
@import './free/functions';

// CORE VARIABLES
@import './free/variables';
@import './bootstrap/variables';
@import './bootstrap/maps';

// BOOTSTRAP CORE
@import './bootstrap/mixins';
@import './bootstrap/utilities';

// BOOTSTRAP CORE COMPONENTS
@import './bootstrap/root';
@import './bootstrap/reboot';
@import './bootstrap/type';
@import './bootstrap/images';
@import './bootstrap/containers';
@import './bootstrap/grid';

// BOOTSTRAP COMPONENTS
@import './bootstrap/tables';
@import './bootstrap/forms';
@import './bootstrap/buttons';
@import './bootstrap/transitions';
@import './bootstrap/dropdown';
@import './bootstrap/button-group';
@import './bootstrap/nav';
@import './bootstrap/navbar';
@import './bootstrap/card';
@import './bootstrap/breadcrumb';
@import './bootstrap/pagination';
@import './bootstrap/badge';
@import './bootstrap/alert';
@import './bootstrap/accordion';
@import './bootstrap/progress';
@import './bootstrap/placeholders';
@import './bootstrap/list-group';
@import './bootstrap/close';
@import './bootstrap/toasts';
@import './bootstrap/modal';
@import './bootstrap/popover';
@import './bootstrap/carousel';
@import './bootstrap/spinners';
@import './bootstrap/offcanvas';
@import './bootstrap/tooltip';

// Helpers
@import './bootstrap/helpers';

// Utilities
@import './free/utilities';
@import './bootstrap/utilities/api';

// MDB CORE
@import './free/mixins';

// MDB CORE COMPONENTS
@import './free/root';
@import './free/reboot';
@import './free/type';
@import './free/colors';
@import './free/shadows';
@import './free/flag';
@import './free/images';

// MDB ANGULAR OVERLAY Z-INDEX
@import './free/overlay';

// MDB FORMS
@import './free/forms/form-control';
@import './free/forms/form-select';
@import './free/forms/form-check';
@import './free/forms/form-file';
@import './free/forms/input-group';
@import './free/forms/validation';
@import './free/forms/form-range';

// MDB COMPONENTS
@import './free/tables';
@import './free/buttons';
@import './free/deprecated';
@import './free/dropdown';
@import './free/button-group';
@import './free/nav';
@import './free/navbar';
@import './free/card';
@import './free/breadcrumb';
@import './free/pagination';
@import './free/badge';
@import './free/alert';
@import './free/progress';
@import './free/list-group';
@import './free/close';
@import './free/modal';
@import './free/toasts';
@import './free/tooltip';
@import './free/popover';
@import './free/scrollspy';
@import './free/ripple';
@import './free/range';
@import './free/accordion';
@import './free/carousel';


@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/text-field-prebuilt.css';
